<template>
  <div v-if="isHaveAccess">
    <b-overlay :show="isLoading" rounded="sm" class="pt-3">
      <b-table
          sticky-header="calc(100vh - 170px)"
          bordered
          responsive
          hover
          head-variant="light table-secondary"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-direction="desc"
          :items="logs"
          :fields="headers"
          :thead-tr-class="'align-middle'"
          class="w-100 pl-2 pr-3"
      >
        <template #cell(eventName)="{ item }">
          <div class="text-left">
            {{ item.eventName }}
          </div>
        </template>
        <template #cell(createdAt)="{ item }" class="color1">
          {{ getFormatDate(item.createdAt) }}
        </template>
        <template #head()="scope">
          <div class="">
            {{ scope.label }}
          </div>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";

export default {
  computed: {
    isHaveAccess() {
      return this.$store.getters.isAdmin || this.$store.getters.isLogyPageAllowed;
    },
  },
  data() {
    return {
      isLoading: false,
      sortBy: 'createdAt',
      sortDesc: true,
      logs: [],
      headers: [
        { label: 'Kód zakázky', key: 'kodZakazky', class: 'w-150px' },
        { label: 'Uživatel', key: 'login', class: 'w-150px' },
        { label: 'Akce', key: 'eventName' },
        { label: 'Datum', key: 'createdAt', class: 'w-150px' },
      ],
    }
  },
  methods: {
    async getLogs() {
      const url = `/admin/getEventLogs`;
      const { data } = await axios.get(url);
      this.logs = data.data;
    },
    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getLogs();
    this.isLoading = false;
  },
};
</script>

<style scoped>

</style>
